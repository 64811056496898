import {useContext,forwardRef} from 'react';

import {default as MuiSnackbar} from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SnackContext from 'store/snack/snack-context';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Snackbar =()=> {
 
    const snackCtx = useContext(SnackContext)
    

  return (

      <MuiSnackbar open={snackCtx.open} autoHideDuration={3000} onClose={snackCtx.handleClose}>
        <Alert onClose={snackCtx.handleClose} severity={snackCtx.severity} sx={{ width: '100%' }}>
            {snackCtx.message}
        </Alert>
      </MuiSnackbar>
    
  );
}

export default Snackbar