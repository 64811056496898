import { lazy, Suspense } from "react";

const Dashboard = lazy(() => import("components/dashboard/Dashboard"));
const Api = lazy(() => import("components/api/Api"));
const ApiList = lazy(() => import("components/api/apilist/ApiList"));
const CreateApi = lazy(() => import("components/api/createapi/CreateApi"));
const EditApi = lazy(() => import("components/api/editapi"));
const Plan = lazy(() => import("components/plan/Plan"));
const AddPlan = lazy(() => import("components/plan/addplan/AddPlan"));
const EditPlan = lazy(() => import("components/plan/editplan"));
const PlanList = lazy(() => import("components/plan/planlist/PlanList"));
const Order = lazy(() => import("components/orders/Order"));
const User = lazy(() => import("components/users/userslist/UserList"));
const Ticket = lazy(() => import("components/ticket/Ticket"));
const CreateTicket = lazy(() => import("components/ticket/createticket/CreateTicket"));
const TicketList = lazy(() => import("components/ticket/ticketlist/TicketList"));
const Auth = lazy(() => import("components/auth"));
const LoginHistory = lazy(() => import("components/loginhistory"));
const Transaction = lazy(() => import("components/transactions"));
const RequestPage = lazy(() => import("components/requests"));
const Profile = lazy(() => import("components/profile"));
const Home = lazy(() => import("components/home"));
const TicketDetail = lazy(() => import("components/ticket/ticketdetail"));
const HelpUs = lazy(() => import("components/helpus"));
const Feedback = lazy(() => import("components/feedback"));
const Income = lazy(() => import("components/income"));
const Setting = lazy(() => import("components/setting"));
const Banner = lazy(() => import("components/banner"));
const ListBanner = lazy(() => import("components/banner/list"));
const EditBanner = lazy(() => import("components/banner/edit"));
const AddBanner = lazy(() => import("components/banner/add"));
const TransactionDetail = lazy(() => import("components/transactions/detail"));
const PaymentMethodList = lazy(() => import("components/payment-method"));
const CreatePaymentMethod = lazy(() => import("components/payment-method/create"));
const EditPaymentMethod = lazy(() => import("components/payment-method/edit"));
const routes = [
  {
    path: "/",
    exact: true,
    element: (
      <Suspense>
        <Dashboard />
      </Suspense>
    ),
    showInMenu: false,
    children: [
      {
        index: true,
        link: "/",
        element: (
          <Suspense>
            <Home />
          </Suspense>
        ),
        title: "dashboard",
        showInMenu: true,
        exact: true,
      },

      {
        path: "plan",
        link: "/plan",
        element: (
          <Suspense>
            <Plan />
          </Suspense>
        ),
        showInMenu: true,
        title: "plan",
        children: [
          {
            index: true,
            link: "/plan",
            element: (
              <Suspense>
                <PlanList />
              </Suspense>
            ),
            showInMenu: true,
            title: "plan list",
          },
          {
            path: "create",
            link: "/plan/create",
            element: (
              <Suspense>
                <AddPlan />
              </Suspense>
            ),
            showInMenu: true,
            title: "create plan",
          },
          {
            path: ":id",
            link: "/plan/:id",
            element: (
              <Suspense>
                <EditPlan />
              </Suspense>
            ),
            showInMenu: false,
            title: "edit plan",
          },
        ],
      },
      {
        path: "api",
        link: "/api",
        element: (
          <Suspense>
            <Api />
          </Suspense>
        ),
        showInMenu: true,
        title: "API",
        children: [
          {
            link: "/api",
            index: true,
            element: (
              <Suspense>
                <ApiList />
              </Suspense>
            ),
            showInMenu: true,
            title: "API List",
          },
          {
            path: "create",
            link: "/api/create",
            element: (
              <Suspense>
                <CreateApi />
              </Suspense>
            ),
            showInMenu: true,
            title: "create API",
          },
          {
            path: ":id",
            link: "/api/:id",
            element: (
              <Suspense>
                <EditApi />
              </Suspense>
            ),
            showInMenu: false,
            title: "edit API",
          },
        ],
      },
      {
        path: "users",
        link: "users",
        element: (
          <Suspense>
            <User />
          </Suspense>
        ),
        title: "users",
        showInMenu: true,
        exact: true,
      },
      {
        path: "users/:user_id/profile",
        link: "users/:id/profile",
        element: (
          <Suspense>
            <Profile />
          </Suspense>
        ),
        showInMenu: false,
        title: "users",
      },
      {
        path: "orders",
        link: "/orders",
        element: (
          <Suspense>
            <Order />
          </Suspense>
        ),
        title: "orders",
        showInMenu: true,
      },
      {
        path: "income",
        link: "/income",
        element: (
          <Suspense>
            <Income />
          </Suspense>
        ),
        title: "income",
        showInMenu: true,
      },
      {
        path: "feedback",
        link: "/feedback",
        element: (
          <Suspense>
            <Feedback />
          </Suspense>
        ),
        title: "feedback",
        showInMenu: true,
      },
      {
        path: "setting",
        link: "/setting",
        element: (
          <Suspense>
            <Setting />
          </Suspense>
        ),
        title: "setting",
        showInMenu: true,
      },
      {
        path: "banner",
        link: "/banner",
        element: (
          <Suspense>
            <Banner />
          </Suspense>
        ),
        title: "banner",
        showInMenu: true,
        children: [
          {
            link: "/banner",
            index: true,
            element: (
              <Suspense>
                <ListBanner />
              </Suspense>
            ),
            title: "banner",
            showInMenu: true,
          },
          {
            path: "create",
            link: "/banner/create",
            element: (
              <Suspense>
                <AddBanner />
              </Suspense>
            ),
            showInMenu: true,
            title: "create Banner",
          },
          {
            path: "edit/:id",
            link: "/banner/edit/:id",
            element: (
              <Suspense>
                <EditBanner />
              </Suspense>
            ),
            showInMenu: false,
            title: "create Banner",
          },
        ],
      },
      {
        path: "payment-method",
        link: "/payment-method",

        title: "Payment Method",
        showInMenu: true,
        children: [
          {
            link: "/payment-method",
            index: true,
            element: (
              <Suspense>
                <PaymentMethodList />
              </Suspense>
            ),
            title: "Payment Method",
            showInMenu: true,
          },
          {
            path: "create",
            link: "/payment-method/create",
            element: (
              <Suspense>
                <CreatePaymentMethod />
              </Suspense>
            ),
            showInMenu: true,
            title: "create Payment Method",
          },
          {
            path: "edit/:id",
            link: "/payment-method/edit/:id",
            element: (
              <Suspense>
                <EditPaymentMethod />
              </Suspense>
            ),
            showInMenu: false,
            title: "edit Payment Method",
          },
        ],
      },
      {
        path: "login-history",
        link: "/login-history",
        element: (
          <Suspense>
            <LoginHistory />
          </Suspense>
        ),
        title: "login-history",
        showInMenu: true,
      },
      {
        path: "payments",
        link: "/payments",
        showInMenu: true,
        title: "payments",
        element: (
          <Suspense>
            <Transaction />
          </Suspense>
        ),
      },
      {
        path: "payments/detail/:id",
        link: "payments/detail/:id",
        showInMenu: false,
        title: "payments detail",
        element: (
          <Suspense>
            <TransactionDetail />
          </Suspense>
        ),
      },
      {
        path: "requests",
        link: "/requests",
        showInMenu: true,
        title: "requests",
        element: (
          <Suspense>
            <RequestPage />
          </Suspense>
        ),
      },

      {
        path: "ticket",
        link: "/ticket",
        element: (
          <Suspense>
            <Ticket />
          </Suspense>
        ),
        showInMenu: true,
        title: "ticket",
        children: [
          {
            link: "/ticket",
            index: true,
            element: (
              <Suspense>
                <TicketList />
              </Suspense>
            ),
            showInMenu: true,
            title: "ticket list",
          },
          {
            path: "create",
            link: "/ticket/create",
            element: (
              <Suspense>
                <CreateTicket />
              </Suspense>
            ),
            showInMenu: true,
            title: "create ticket",
          },
          {
            path: ":id/detail",
            link: "/ticket/:id/detail",
            element: (
              <Suspense>
                <TicketDetail />
              </Suspense>
            ),
            showInMenu: false,
            title: "create ticket",
          },
        ],
      },
      {
        path: "help-us",
        link: "/help-us",
        showInMenu: true,
        title: "help-us",
        element: (
          <Suspense>
            <HelpUs />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/auth/login",
    element: (
      <Suspense>
        <Auth />
      </Suspense>
    ),
  },
];

export default routes;
