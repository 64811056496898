import { useState } from "react"
import SnackContext from "./snack-context"


const SnackProvider = (props)=>{

    const[open,setOpen] = useState(false)
    const[severity,setSeverity] = useState('')
    const[message,setMessage] = useState('')
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
        setMessage("");
      };

    const handleOpen =(message,severity)=>{
       
        setMessage(message)
        setSeverity(severity)
        setOpen(true)
    }  
    
    const values={
        open:open,
        severity:severity,
        message:message,
        handleClose:handleClose,
        handleOpen:handleOpen
    }


    return(
        <SnackContext.Provider value={values}>
            {props.children}
        </SnackContext.Provider>
    )
}

export default SnackProvider