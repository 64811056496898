import {useState} from 'react'
import BannerContext from './banner-context'

const BannerProvider = ({children})=>{
    const [show,setShow] = useState('')
    const[content,setContent] = useState('')

    const handleOpen = (img)=>{
        setContent(img)
        setShow(true)
    }

    const handleClose = ()=>{
        setShow(false)
        setContent('')
    }

    const values={
        show,
        content,
        handleOpen,
        handleClose
    }

    return(
        <BannerContext.Provider value={values}>
            {children}
        </BannerContext.Provider>
    )

}

export default BannerProvider