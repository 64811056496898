import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Dialog, DialogTitle, Box, IconButton, DialogContent, DialogActions, Divider } from "@mui/material";
import { useContext } from "react";
import DialogContext from "store/dialog/dialog-context";
import Title from "../Title";
import CloseIcon from "components/icons/CloseIcon";

const DialogBox = () => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dialogCtx = useContext(DialogContext);
  return (
    <Dialog
      open={dialogCtx.open}
      onClose={dialogCtx.handleClose}
      fullScreen={fullScreen}
      PaperProps={{
        style: {
          borderRadius: 16,
          maxWidth: "600px",
          minWidth: "calc(100% - 800px)",
          height: "auto",
        },
      }}
    >
      <DialogTitle sx={{ p: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Title>{dialogCtx.title}</Title>
          <IconButton fontSize="small" onClick={dialogCtx.handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent>{dialogCtx.content}</DialogContent>
      <DialogActions>{dialogCtx.action}</DialogActions>
    </Dialog>
  );
};

export default DialogBox;
