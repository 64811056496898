const { createContext } = require("react");


const AuthContext = createContext({
    user:{
        token:null,
        firstName:null,
        lastName:null,
        email:null,
        role:null
    },
    handleLogin:()=>{},
    handleLogout:()=>{}
})

export default AuthContext