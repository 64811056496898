import { useState } from "react"
import AuthContext from "./auth-context"

let newUser = localStorage.getItem('admin')
let user = JSON.parse(newUser)
let initialState = {
    firstName:   user ? user.firstName   : null,
    lastName:    user ? user.lastName    : null,
    email:       user ? user.email       : null,
    token:       user ? user.token       : null,
    role:        user ? user.role        : null,

}

const AuthProvider = (props)=>{

    const [user,setUser] = useState(initialState)

    const loginHandler = (token,role,firstName,lastName,email)=>{
        localStorage.removeItem('admin')
        
        setUser({
            firstName,
            lastName,
            email,
            token,
            role,
        });
        const user = {
            firstName:firstName,
            lastName:lastName,
            email:email,
            token:token,
            role:role,
        }
        localStorage.setItem('admin',JSON.stringify(user))

    }

    const logoutHandler = ()=>{
        localStorage.removeItem('admin')
        setUser({})
     
    }

    const contextValue={
        user:user,
        handleLogin:loginHandler,
        handleLogout:logoutHandler,
    }
    
    return(
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthProvider