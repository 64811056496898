import { Divider, Typography } from "@mui/material"



const Title = (props)=>{

    return(
        <>
            <Typography component={'div'} variant="title" sx={{textTransform:'capitalize'}} >
                {props.children}
                
            </Typography>
            
           
        </>
        
    )
}

export default Title