import { createContext } from "react";

const StaticContext = createContext({
  filter: undefined,
  setFilter: (filter) => {},
  getFilter: () => {},
  getData: () => {},
});

export default StaticContext;
