const BASE_URL = process.env.REACT_APP_API_ROOT;

export const planUrl = BASE_URL + "api/v1/management/plans";
export const addPanUrl = BASE_URL + "api/v1/owner/plans";
export const editPlanStatus = BASE_URL + "api/v1/owner/plans";
export const apiUrl = BASE_URL + "api/v1/management/apis";
export const addApiUrl = BASE_URL + "api/v1/owner/apis";
export const editApiUrl = BASE_URL + "api/v1/owner/apis";
export const loginUrl = BASE_URL + "api/v1/auth/email/signin";
export const loginChartUrl = BASE_URL + "api/v1/management/charts/logins";
export const loginListUrl = BASE_URL + "api/v1/management/logins";
export const paymentListUrl = BASE_URL + "api/v1/management/payments";
export const getPaymentUrl = BASE_URL + "api/v1/history/payments/id";
export const paymentChartUrl = BASE_URL + "api/v1/management/charts/payments";
export const requestListUrl = BASE_URL + "api/v1/management/requests";
export const requestChart = BASE_URL + "api/v1/management/charts/requests";
export const orderUrl = BASE_URL + "api/v1/management/orders";
export const userUrl = BASE_URL + "api/v1/management/users";
export const userEdit = BASE_URL + "api/v1/owner/users";
export const ticketUrl = BASE_URL + "api/v1/tickets/list";
export const createTicket = BASE_URL + "api/v1/tickets";
export const ticketDetail = BASE_URL + "api/v1/tickets";
export const profileUrl = BASE_URL + "api/v1/management/users/profile";
export const stuff = BASE_URL + "api/v1/management/stuff";
export const dashboardUrl = BASE_URL + "api/v1/management/dashboard";
export const RegisterChartUrl = BASE_URL + "api/v1/management/charts/users";
export const sendMessage = BASE_URL + "api/v1/tickets/messages";
export const ticketOptions = BASE_URL + "api/v1/tickets/prepare";
export const helpusUrl = BASE_URL + "api/v1/management/helpus/contents";
export const stuffUrl = BASE_URL + "api/v1/management/stuff";
export const appUrl = BASE_URL + "api/v1/tickets/unread";
export const getSettingUrl = BASE_URL + "api/v1/settings";
export const getOwnerSettingUrl = BASE_URL + "api/v1/owner/settings/list";
export const updateOwnerSettingUrl = BASE_URL + "api/v1/owner/settings/update";
export const createOwnerSettingUrl = BASE_URL + "api/v1/owner/settings/create";
export const feebackUrl = BASE_URL + "api/v1/management/rates/list";
export const incomeUrl = BASE_URL + "api/v1/management/history/incomes";
export const getOptions = BASE_URL + "api/v1/options";
export const createBannerUrl = BASE_URL + "api/v1/owner/banners/create";
export const updateBannerUrl = BASE_URL + "api/v1/owner/banners/";
export const deleteBannerUrl = BASE_URL + "api/v1/owner/banners/";
export const bannerUrl = BASE_URL + "api/v1/owner/banners/list";
export const getBanner = BASE_URL + "api/v1/banners";

export const paymentMethodUrl = BASE_URL + "api/v1/owner/pmethods";
