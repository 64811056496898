const { createContext } = require("react");


const DialogContext = createContext({

    open:false,
    title:null,
    content:null,
    actions:null,
    handleTitle:()=>{},
    handleContent:()=>{},
    handleActions:()=>{},
    handleOpen:()=>{},
    handleClose:()=>{},
    

})

export default DialogContext