const { createContext } = require("react");


const SnackContext = createContext({
    severity:'',
    message:'',
    open:false,
    handleClose:()=>{},
    handleOpen:()=>{},

})
export default SnackContext