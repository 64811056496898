import { createTheme } from "@mui/material/styles";
const primary = "#6C86DB";

export const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      dashboardIcon: "#787777",
      selectedDashboard: "#FFFFFF",
      text: "#7F8081 ",
      checkBox: "#6CDBB2",
      price: "#6CDBB2",
    },
    secondary: {
      main: "#6CDBB2",
      text: "#DBB26C",
    },
    bage: {
      main: "#DB6C6C",
    },
    landing: {
      main: "#76323F",
      darkGray: "#67595E",
      gray: "#A49393",
      pink: "#EED6D3",
      divide: "#FBF2F3",
      faq: "#707070",
    },
    text: {
      main: "#7F8081",
    },
  },
  typography: {
    h1: { fontSize: "30px" },
    h2: { fontSize: "28px" },
    h3: { fontSize: "22px" },
    h4: { fontSize: "19px" },
    h5: { fontSize: "17px" },
    h6: { fontSize: "14px", fontWeight: "bold" },
    body1: { fontSize: "12px" },
    body2: { fontSize: "10px" },
    fontFamily: "Gotham",
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#c7c4c4",
          },
          "&.Mui-focused": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: primary,
            },
          },
        },
        notchedOutline: {
          borderColor: "#E7E7E7",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          background: "#E5EAFF",
          "& th": {
            "&:first-of-type": {
              borderRadius: "16px 0px 0px 0px",
            },
            "&:last-child": {
              borderRadius: "0px 16px 0px 0px",
            },
            color: "#7897FE",
            border: 0,
            fontSize: "12px",
            fontWeight: "Bold",
            textTransform: "capitalize",
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          fontSize: "12px !important",
          "& tr": {
            "&:nth-of-type(odd)": {
              borderRadius: "16px 0px 0px 16px",
              background: "#F7F7F7",
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "12px !important",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "buy" },
          style: {
            textTransform: "none",
            backgroundColor: "#6CDBB2",
            borderRadius: "8px",
            color: "#FFFFFF",
            minWidth: 160,
            maxWidth: 180,
            padding: "8px 12px",
            "&:hover": {
              background: "#3fdfa4",
            },
            "&.Mui-disabled": {
              background: "#0000001f",
              color: "#00000042",
            },
          },
        },
        {
          props: { variant: "secondaryButton" },
          style: {
            textTransform: "none",
            backgroundColor: "#EBEBEB",
            borderRadius: "8px",
            color: "#787777",
            minWidth: 100,
            maxWidth: 120,
            padding: "8px 12px",
            "&:hover": {
              background: "#D2D0D0",
            },
          },
        },
        {
          props: { variant: "table" },
          style: {
            textTransform: "none",
            borderColor: "#E9E8E8 !important",
            borderRadius: "6px",
            border: "1px",
            borderStyle: "solid",
            backgroundColor: "white",
            color: "#707070",
            fontSize: "10px",
            padding: "4px 8px",
            minWidth: 0,
            "&:hover": {
              cursor: "pointer",
              boxShadow: "none",
              backgroundColor: "white",
            },
          },
        },
        {
          props: { variant: "status" },
          style: ({ ownerState }) => {
            if (ownerState.color === "paid")
              return {
                textTransform: "none",
                backgroundColor: "#C3FFDE",
                color: "#5DBC7E",
                fontSize: "10px",
                padding: "4px 8px",
                "&:hover": {
                  cursor: "auto",
                  boxShadow: "none",
                  backgroundColor: "#C3FFDE",
                },
              };
            if (ownerState.color === "cancelled" || ownerState.color === "canceled")
              return {
                textTransform: "none",
                backgroundColor: "#F8DDE2",
                color: "#F56666",
                fontSize: "10px",
                padding: "4px 8px",
                "&:hover": {
                  cursor: "auto",
                  boxShadow: "none",
                  backgroundColor: "#F8DDE2",
                },
              };
            if (ownerState.color === "paying")
              return {
                textTransform: "none",
                backgroundColor: "#E4EAF8",
                color: "#7897FE",
                fontSize: "10px",
                padding: "4px 8px",
                "&:hover": {
                  cursor: "auto",
                  boxShadow: "none",
                  backgroundColor: "#E4EAF8",
                },
              };
            if (ownerState.color === "processing")
              return {
                textTransform: "none",
                backgroundColor: "#EBF1FF",
                color: "#0034df",
                fontSize: "10px",
                padding: "4px 8px",
                "&:hover": {
                  cursor: "auto",
                  boxShadow: "none",
                  backgroundColor: "#E4EAF8",
                },
              };
            if (ownerState.color === "expired")
              return {
                textTransform: "none",
                backgroundColor: "#ff7400",
                color: "white",
                fontSize: "10px",
                padding: "4px 8px",
                "&:hover": {
                  cursor: "auto",
                  boxShadow: "none",
                  backgroundColor: "#ff7400",
                },
              };
            if (ownerState.color === "new")
              return {
                textTransform: "none",
                backgroundColor: "#e5e1e1",
                color: "#938686",
                fontSize: "10px",
                padding: "4px 8px",

                "&:hover": {
                  cursor: "auto",
                  boxShadow: "none",
                  backgroundColor: "#e5e1e1",
                },
              };
              if (ownerState.color === "reserved")
                return {
                  textTransform: "none",
                  backgroundColor: "#e5e1e1",
                  color: "black",
                  fontSize: "10px",
                  padding: "4px 8px",

                  "&:hover": {
                    cursor: "auto",
                    boxShadow: "none",
                    backgroundColor: "#e5e1e1",
                  },
                };
            if (ownerState.color === "confirmed")
              return {
                textTransform: "none",
                backgroundColor: "#bb59a19e",
                color: "#713359",
                fontSize: "10px",
                padding: "4px 8px",
                "&:hover": {
                  cursor: "auto",
                  boxShadow: "none",
                  backgroundColor: "#bb59a19e",
                },
              };
            if (ownerState.color === "Normal")
              return {
                textTransform: "none",
                backgroundColor: "#E9EFFD",
                color: "#6C86DB",
                fontSize: "10px",
                padding: "4px 8px",
                "&:hover": {
                  cursor: "auto",
                  boxShadow: "none",
                  backgroundColor: "#BDCDF8",
                },
              };
            if (ownerState.color === "Important")
              return {
                textTransform: "none",
                backgroundColor: "#F6CDD6",
                color: "#F27878",
                fontSize: "10px",
                padding: "4px 8px",
                "&:hover": {
                  cursor: "auto",
                  boxShadow: "none",
                  backgroundColor: "#F6CDD6",
                },
              };
          },
        },
      ],
      styleOverrides: {
        contained: {
          background: "#7897FE",
          padding: "8px 12px",
          fontSize: "12px",
          color: "white",
          boxShadow: "none",
          borderRadius: "8px",
          textTransform: "None",
          minWidth: 160,
          maxWidth: 180,
          textTransform: "capitalize",
        },
        outlined: {
          padding: "8px 12px",
          fontSize: "12px",

          boxShadow: "none",
          borderRadius: "8px",
          textTransform: "None",
          borderColor: "#F56666",
          color: "#F56666",
          minWidth: 160,
          maxWidth: 180,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.type === "card" && {
            boxShadow: "none",
            borderRadius: "16px",
            padding: "16px 32px",
          }),
        }),
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "title" },
          style: ({ ownerState, theme }) => ({
            color: theme.palette.primary.text,
            fontSize: "18px",
            fontWeight: "bold",
            marginRight: "8px",
            fontFamily: "Gotham",
            marginBottom: "8px",
          }),
        },
      ],
    },
  },
});
