function CloseIcon() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24.855"
        height="24.855"
        viewBox="0 0 24.855 24.855"
      >
        <g transform="translate(-3.929 -3.929)">
          <g transform="translate(3.929 3.929)">
            <path
              fill="#9e9e9e"
              d="M14.179 16.356l-9.8 9.8a1.54 1.54 0 102.178 2.178l9.8-9.8 9.8 9.8a1.54 1.54 0 102.178-2.178l-9.8-9.8 9.8-9.8a1.54 1.54 0 10-2.18-2.176l-9.8 9.8-9.8-9.8A1.54 1.54 0 004.38 6.557l9.8 9.8z"
              transform="translate(-3.929 -3.929)"
            ></path>
          </g>
        </g>
      </svg>
    );
  }
  
  export default CloseIcon;