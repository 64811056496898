import { createContext } from "react"



const ApplicationSetting = createContext({
    ticket:0,
    error:false,
    setError:()=>{},
    setTicket:()=>{}

})

export default ApplicationSetting