import { useState, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AuthContext from "../store/auth-context";
import SnackContext from "store/snack/snack-context";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const authCtx = useContext(AuthContext);
  const snackCtx = useContext(SnackContext);
  const navigate = useNavigate();
  const sendRequest = useCallback((requestConfig, applyData, access_token = null) => {
    setIsLoading(true);
    const options = {
      ...requestConfig,
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${access_token || authCtx?.user?.token}` },
      // headers config
    };
    return axios(options)
      .then((response) => {
        setIsLoading(false);
        if (response.data.status_code === 200) {
          applyData(response);
        } else if (response.data.status_code !== 200) {
          snackCtx.handleOpen(response.data.message, "error");
        }
        if (response.data.status_code === 403) {
          authCtx.handleLogout();
          navigate("/auth/login", { replace: true });
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response?.status === 401) {
          snackCtx.handleOpen("Please Log in to continue!", "warning");
          authCtx.handleLogout();
          navigate("/auth/login", { replace: true });
        }
        setIsLoading(false);
        setError(true);
      });
  }, []);

  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;
