import { useState } from "react"
import ApplicationSetting from "./setting-context"



const SettingProvider = ({children})=>{
    const[tickets,setTickets] = useState(0)
    const[message,setMessage] = useState(false)

    const ticketHandler = (number)=>{
        setTickets(number)
    }

    const messageHandler = (error) =>{
        setMessage(error)
    }

    const contextValue ={
        ticket:tickets,
        message:message,
        setError:messageHandler,
        setTicket:ticketHandler
    }
    return(
        <ApplicationSetting.Provider value={contextValue}>
            {children}
        </ApplicationSetting.Provider>
    )

}
export default SettingProvider