import { useState } from "react"
import DialogContext from "./dialog-context"



const DialogProvider = ({children})=>{

    const [open,setOpen] = useState(false)
    const [title,setTitle] = useState(null)
    const [content,setContent] = useState(null)
    const [action,setAction] = useState(null)


    const titleHandler = (title)=>{
        setTitle(title)
    }
    const contentHandler = (content)=>{
        setContent(content)
    }

    const actionHandler =(action)=>{
        setAction(action)
    }

    const closeHandler = ()=>{
        setOpen(false)
        setTitle(null)
        setContent(null)
        setAction(null)
    }

    const openHandler = ()=>{
        setOpen(true)
    }

    const contextValue={
        open:open,
        title:title,
        content:content,
        action:action,
        handleTitle:titleHandler,
        handleContent:contentHandler,
        handleActions:actionHandler,
        handleOpen:openHandler,
        handleClose:closeHandler,
    }
    return(
        <DialogContext.Provider value={contextValue}>
            {children}
        </DialogContext.Provider>
    )

}

export default DialogProvider