import {createContext} from 'react'


const BannerContext = createContext({
    show:false,
    content:'',
    handleOpen:(data)=>{},
    handleClose:()=>{}


})

export default BannerContext