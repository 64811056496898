import { stuffUrl } from "extenstions/apiUrl";
import useHttp from "hooks/useHttp";
import { useEffect, useState } from "react";
import StaticContext from "./static-context";

const StaticProvider = ({ children }) => {
  const [filter, setFilterData] = useState(undefined);
  const { sendRequest } = useHttp();
  useEffect(() => {
    const sendRequestHandler = (res) => {
      setFilter(res.data.data);
    };
    const options = {
      method: "get",
      url: stuffUrl,
    };
    sendRequest(options, sendRequestHandler);
  }, [sendRequest]);
  const setFilter = (filter) => {
    setFilterData(filter);
  };

  const getData = () => {
    const sendRequestHandler = (res) => {
      setFilter(res.data.data);
    };
    const options = {
      method: "get",
      url: stuffUrl,
    };
    sendRequest(options, sendRequestHandler);
  };
  const getFilter = () => {
    return filter;
  };

  const contextValue = {
    setFilter,
    getFilter,
    getData,
  };
  return <StaticContext.Provider value={contextValue}>{children}</StaticContext.Provider>;
};
export default StaticProvider;
