import "./App.css";
import { useRoutes } from "react-router-dom";
import routes from "routes";
import AuthProvider from "store/AuthProvider";
import DialogProvider from "store/dialog/DialogProvider";
import DialogBox from "components/layout/dialog";
import SnackProvider from "store/snack/SnackProvider";
import Snackbar from "components/layout/snack";
import SettingProvider from "store/setting/SettingProvider";
import StaticProvider from "store/static-data/StaticProvider";
import BannerProvider from "store/banner/BannerProvider";

function App() {
  let appRoutes = useRoutes(routes);

  return (
    <AuthProvider>
      <DialogProvider>
        <SnackProvider>
          <SettingProvider>
            <StaticProvider>
              <BannerProvider>
              {appRoutes}
              </BannerProvider>
              
            </StaticProvider>
          </SettingProvider>
          <Snackbar />
        </SnackProvider>
        <DialogBox />
      </DialogProvider>
    </AuthProvider>
  );
}

export default App;
